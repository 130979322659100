<template>
	<div class="h-100 dis-flex-com ft-sz-14 po-r">
		<div class="po-r bd-bl-r-10 bd-br-r-10 userbody">
			<img class="po-a w-100 h-100" src="../../../assets/image/my-bg.png" alt="">
			<div class="po-a w-100 h-100 dis-flex-com" style="z-index: 9;">
				<div class="flx-1 dis-flex-com pd-x-15 pd-t-15 pd-b-15">
					<div class="dis-flex-row al-c" style="margin-top: auto;">
						<img class="img-tou bd-r-b50 bk-co-ff" style="padding: 4px;" :src="userData.wx_headimgurl" alt="">
						<div class="flx-1 pd-x-15 ft-co-ff">
							<div class="ft-sz-18 ft-w-b">
								{{userData.realname || userData.username}}
							</div>
							<div class="mg-t-10 ft-w-b">
								{{userData.usermobile}}
							</div>
						</div>
						<van-icon name="arrow" color="#ffffff" @click="urlClick" />
					</div>
					<div class="dis-flex-row al-c mg-t-15 bd-r-10 pd-x-15 pd-t-5 pd-b-5" style="background-color: rgba(255,255,255,.3);">
						<div class="mg-r-auto ft-co-ff ft-sz-12 ft-w-b">
							{{companyinfo.companyname}}
						</div>
						<div class="ft-sz-12 bk-co-ff pd-x-15 pd-t-5 pd-b-5 bd-r-50" style="color: #fd704d;" v-if="company_list != ''" @click="switchCompanyList(true)">
						切换单位
					</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pd-t-5" style="background-color: #F5F5F5;"></div>
		<div class="dis-flex-row txt-c pd-y-10">
			<div class="flx-1 dis-flex-com po-r ju-c borderafter">
				<div class="">
					<div class="ft-sz-18">
						-
						<!-- 阅读量 -->
					</div>
					<div class="ft-sz-12 ft-co-99">
						<span class="qiusofont">&#xe6b6;</span>
						积分
					</div>
				</div>
			</div>
			<div class="flx-1 dis-flex-com ju-c">
				<div class="">
					<div class="ft-sz-18">
						-
					</div>
					<div class="ft-sz-12 ft-co-99">
						<span class="qiusofont">&#xe68d;</span>
						点赞
					</div>
				</div>
			</div>
			<div class="flx-1 dis-flex-com po-r ju-c borderafter1">
				<div class="">
					<div class="ft-sz-18">
						-
					</div>
					<div class="ft-sz-12 ft-co-99">
						<span class="qiusofont">&#xe69c;</span>
						评论
					</div>
				</div>
			</div>
		</div>
		<div class="pd-t-5" style="background-color: #F5F5F5;"></div>
		<div class="ft-sz-14 pd-x-10 pd-t-10 pd-b-10 ">
			<div class="bd-r-5 pd-x-10 pd-b-10" style="box-shadow: 0 2px 5px 0 #999999;">
				<van-tabs @change="vanTanChange">
				  <van-tab v-for="(item,index) in product_list" :key="'product'+index" :title="item.productname">
				    <div class="pd-t-10 pd-b-10 bd-bott">
						<div class="dis-flex-row txt-c borderafterTab" v-if="item.producturl == '/changyue'">
							<div class="flx-1 dis-flex-com po-r ju-c borderafter">
								<div class="">
									<div class="ft-co-99">
										今日通过量
										<!-- 阅读量 -->
									</div>
									<div class="ft-sz-18 mg-t-10">
										{{userDtat.firstreviewpassnum + userDtat.lastreviewpassnum}}
									</div>
								</div>
							</div>
							<div class="flx-1 dis-flex-com ju-c">
								<div class="">
									<div class="ft-co-99">
										今日待审量
									</div>
									<div class="ft-sz-18 mg-t-10">
										{{userDtat.waitreviewnum}}
									</div>
								</div>
							</div>
							<div class="flx-1 dis-flex-com po-r ju-c borderafter1">
								<div class="">
									<div class="ft-co-99">
										今日拒绝量
									</div>
									<div class="ft-sz-18 mg-t-10">
										{{userDtat.firstnopassnum + userDtat.lastnopassnum}}
									</div>
								</div>
							</div>
						</div>
						<div class="txt-c ft-co-99" v-else>
							暂无信息
						</div>
					</div>
					<div class="ft-sz-14" v-if="item.producturl == '/changyue'">
						<div class="pd-x-10" @click="setClick('/mycomments','/changyue')">
							<div class="dis-flex-row al-c pd-t-10 pd-b-10">
								<div class="mg-r-auto">
									历史评论
								</div>
								<van-icon name="arrow" color="#d4d4d4" />
							</div>
						</div>
					</div>
				  </van-tab>
				</van-tabs>
			</div>
		</div>
		<div class="dis-flex-com">
			<div style="margin-top: auto;padding: 0 60px 120px;">
				<button class="pd-y-10 mg-t-15 w-100 ft-co-ff" type="button" @click="returnClick('1')">退出登陆</button>
			</div>
		</div>
		<div class="tabbarheight"></div>
		<van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000" @change="activeCheng">
		  <van-tabbar-item>
		    <span>精选</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6b6;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>资讯</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6ae;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>服务</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6b0;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>我的</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6ac;</span>
		    </template>
		  </van-tabbar-item>
		</van-tabbar>
		<van-overlay :show="companyListStatus" z-index="9999" @click="show = false">
			  <div class="wrapper bk-co-ff h-100" @click.stop>
			    <CompanyList :company_list="company_list"></CompanyList>
			  </div>
		</van-overlay>
	</div>
</template>
<style scoped>
	.van-icon{font-size:20px !important;}
	.userbody{
		height: 140px;
	}
	.login {
		padding: 60px 48px 0 48px;
	}
	.borderafterTab{height: 68px;}
	.borderafter::after {
		content: '';
		position: absolute;
		right: 0;
		top: 25%;
		height: 50%;
		border-right: 2px solid #f5f5f5;
	}
	.borderafter1::after {
		content: '';
		position: absolute;
		left: 0;
		top: 25%;
		height: 50%;
		border-right: 2px solid #f5f5f5;
	}
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
</style>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import CompanyList from '../../components/companyList.vue';
	import {
		Toast,
		Overlay 
	} from 'vant';
	var {
		changYueAcions,
		adminGeneral
	} = http
	export default {
		name: 'My',
		inject:['reload'],
		components: {CompanyList},
		data() {
			return {
				active:3,
				userData: {
					realname: "",
					usermobile: "",
					username: "",
					wx_headimgurl: ""
				},
				companyinfo: {
					companyname: ''
				},
				company_list:[],
				companyListStatus:false,
				userDtat: {/*畅阅*/
					delnum: 0,
					/*删除量*/
					lastnopassnum: 0,
					/*终审拒绝 */
					lastreviewpassnum: 0,
					/*终审通过*/
					firstnopassnum: 0,
					/*初审拒绝 */
					firstreviewpassnum: 0,
					/*初审通过*/
					waitreviewnum: 0,
					/*待审*/
					manuscriptnum: 0,
					/*稿件阅读*/
					totalcommetnnum: 0,
					/*总评论量*/
				},
				product_list:[],/*产品列表*/
				changyueStatus:false,
				user:{}
			}
		},
		created(){},
		mounted() {
			if(localStorage.getItem('user')){
				this.user = JSON.parse(localStorage.getItem('user'));
			}
			this.userInfo();
			this.companyList();
			this.pcompanyList();
		},
		activated() {},
		methods: {
			setClick(e,val){
				let s = this;
				var product = s.product_list.filter(item=>{
					return item.producturl == val
				})
				localStorage.setItem('productid',product[0].productid)
				this.$router.push(e)
			},
			userInfo() {/*获取用户信息*/
				var s = this;
				adminGeneral.userInfo.http({
					"companyid": localStorage.getItem('companyid'),
					"userid": s.user.userid,
				}).then(res => {
					if (res.getret === 0) {
						s.userData = res.info.user
					} else {
						Toast(data.msg || '获取数据失败')
					}
				})
			},
			companyList(){/*用户握手获取公司列表*/
				var s = this;
				adminGeneral.shakeHands.http({}).then(res => {
					if (res.getret === 0) {
						if(res.info.company_list.length >0){
							var companyinfo = res.info.company_list.filter(item=>{
								return item.companyid == localStorage.getItem('companyid')
							})
							s.companyinfo = JSON.parse(JSON.stringify(companyinfo))[0];
							s.company_list = res.info.company_list
						}
					}
				})
			},
			urlClick() { /*路由跳转*/
				this.$router.push('/personal')
			},
			switchCompanyList(val){/*切换单位*/
				this.companyListStatus = val?true:false;
				if(!val){
					this.reload();
				}
			},
			returnClick(val) { /*0：返回/1：登出*/
				localStorage.removeItem('user')
				localStorage.removeItem('companyid')
				this.$router.push('/login')
			},
			activeCheng(e){
				if(e == 1){
					this.$router.push('/information')
				}else if(e==2){
					this.$router.push('/service')
				}else if(e==3){
					
				}else{
					this.$router.push('/')
				}
			},
			pcompanyList(){/*用户获取公司已授权产品列表*/
				var _this = this;
				adminGeneral.userProductList.http({
					"condition":{
						"companyid": localStorage.getItem('companyid'),
						"page_index":0,
						"page_size":100
					}
				}).then(res => {
					if (res.getret === 0) {
						_this.product_list = res.list
						res.list.filter(item=>{
							if(item.producturl == '/changyue'){
								_this.changyueStatus = true;
							}
						})
						if(res.list.length == 1){
							_this.userDataAll(_this.product_list[0])
						}
					}
				})
			},
			vanTanChange(item){
				var s = this;
				// console.log(s.product_list[item].producturl)
				if(s.product_list[item].producturl == '/changyue'){
					s.userDataAll(s.product_list[item])
				}
			},
			userDataAll(item){
				var s = this;
				changYueAcions.userStatistical.http({
					"condition":{
						"page_index":0,
						"page_size":10,
						"companyid": localStorage.getItem('companyid'),
						"productid": item.productid,
						"userid":s.user.userid,
						"departmentid":"-1"
					},
				}).then(res => {
					if (res.getret === 0) {
						if (res.list.length != 0) {
							s.userDtat = res.list[0];
						}
					}
				})
			},
		},
		destroyed() {}
	}
</script>
