<template>
	<div class="h-100 dis-flex-com">
		<div class="flx-1 dis-flex-com ft-sz-14">
			<div class="pd-x-15 pd-t-5 pd-b-5 bk-co-99">
				<div class="txt-c ft-co-99 pd-y-10 bk-co-f5 bd-r-30">
					<van-icon name="search" />
					输入关键字搜索
				</div>
			</div>
			<div class="flx-1 dis-flex-com" v-if="typeDatalist != ''">
				<van-tabs @click="tabClick" :active="activeInformationTab">
				  <van-tab v-for="(items,indexs) in typeDatalist" :key="'items'+indexs" :title="items.typename">
				    <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott" v-for="(item,index) in dataSource" :key="'tab'+index">
				    	<div class="mg-r-10" @click="detailInfo(item)">
				    		<div class="ft-le-10">{{item.title}}</div>
				    		<div class="pd-t-5 ft-sz-12 ft-co-99">{{util.formatDate('cn',item.createtime)}}</div>
				    	</div>
				    	<div class="mg-l-auto" v-if="item.filearray != ''">
				    		<van-image
				    		  width="2.3rem"
				    		  fit="contain"
				    		  :src="'http://file.zmiti.com/index/files/file?p=' + item.filearray"
				    		/>
				    	</div>
				    </div>
				  </van-tab>
				</van-tabs>
				<div class="flx-1"></div>
				<van-pagination v-model="current" :items-per-page="pageSize" :total-items="total" mode="simple" :show-page-size="pageSize" @change="pageCheng" />
			</div>
		</div>
		<div class="tabbarheight"></div>
		<van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000" @change="activeCheng">
		  <van-tabbar-item>
		    <span>精选</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6b6;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>资讯</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6ae;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>服务</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6b0;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>我的</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6ac;</span>
		    </template>
		  </van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<style scoped>
</style>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import {
		Toast
	} from 'vant';
	var {
		adminGeneral
	} = http
	var he = require('he');
	export default {
		name: 'Information',
		components: {},
		data() {
			return {
				util:util,
				active:1,
				currentPage:1,
				informationsData:[],
				searchvalue:'',
				sourceData:[],
				vuidData:[],
				productid:0,
				dataSource:[],
				currentIndex:0,
				channelIndex:0,
				currentNewsid:0,
				sidebarData:[
					{ title: '出差宝' },
				  { title: '会议通' },
				  { title: '福利厅' },
				  { title: '读书社' },
				  { title: '资讯窗' },
				  { title: '活动聚' },
				  { title: '互动台' },
				],
				submenuData:[{ title: '' }],
				typeDatalist:[],
				specialnum:-1,
				informationid:0,
				infotypeid:0,
				productlist:[],
				diystatus:false,
				diylist:[],
				isLoading: false,	
				total:0,
				current:1,
				pageSize:10,
				activeInformationTab:''
			}
		},
		created(){},
		mounted() {
			this.activeInformationTab = JSON.parse(localStorage.getItem('informationTab')) || 0;/*资讯Tab选项*/
			this.pcompanyList();
		},
		activated() {},
		methods: {
			pcompanyList(){/*用户获取公司已授权产品列表*/
				var _this = this;
				adminGeneral.userProductList.http({
					"condition":{
						"companyid": localStorage.getItem('companyid'),
						"page_index":0,
						"page_size":100
					}
				}).then(res => {
					if (res.getret === 0) {
						let proid=res.list.filter(item=>item.producturl=='/information/index')
						if(proid.length>0){
							/*获取产品id*/
							_this.informationid = proid[0].productid
							localStorage.setItem('productid',proid[0].productid)
							setTimeout(()=>{
								/*获取栏目*/
								_this.informationTypeList(proid[0].productid)
							},300)
						}
					}
				})
			},
			informationTypeList(productid){/*获取用户信息类型列表 2001304*/
				var s = this;
				adminGeneral.userInformationTypeList.http({
					"condition":{
						"companyid":localStorage.getItem('companyid'),
						"page_index": 0,
						"page_size": 1000,
						"productid": productid,
					}
				}).then(data => {
					if (data.getret === 0) {
						if(data.list.length>1){
						  	let columnData=[];
						  	let conlumnType=[];
						  	let diylist=[];
						  	data.list.map((item,index)=>{
						  		/*过滤意见箱的分类*/
						  		if(item.specialnum!=1){
						      		columnData.push({
						      			title:item.typename
						      		});		      		
						  		}
						  		switch(item.specialnum){
									case 0:{
										diylist.push(item)
										break;
									}
						  			case 2:{
						  				diylist.push(item)
						  				break;
						  			}
						  			case 3:{
						  				diylist.push(item)
						  				break;
						  			}
						  			case 4:{
						  				diylist.push(item)
						  				break;
						  			}
						  			case 5:{
						  				diylist.push(item)
						  				break;
						  			}
						  			default:{
						  				break;
						  			}
						  		}
						  	})
						  	// console.log(diylist,'conlumnType')
						  	s.typeDatalist = diylist;
						  	s.submenuData = columnData;
						  	s.infotypeid = diylist[s.activeInformationTab].infotypeid;
						  	s.specialnum = diylist[s.activeInformationTab].specialnum;
						  	s.diylist = diylist      	
						  	/*获取第一个栏目的内容*/
						  	s.getDataList()
						}
					}
				})
			},
			tabClick(val){/*tab选项卡*/
				localStorage.setItem('informationTab',JSON.stringify(val));
				this.current = 1;
				this.infotypeid = this.typeDatalist[val].infotypeid;
				this.getDataList();
			},
			pageCheng(val){
				this.current = val;
				this.getDataList();
			},
			getDataList(){
				var s = this;
				adminGeneral.toViewList.http({
					"condition":{
					  "companyid":localStorage.getItem('companyid'),
					  "productid":s.informationid,
					  "infotypeid":s.infotypeid,
					  "title":s.searchvalue,
					  "status":2,//通过审核的
					  "page_index":s.current-1,
					  "page_size":s.pageSize
					}
				}).then(data => {
					if (data.getret === 0) {
						s.total = data.total;
						s.dataSource = data.list;
						s.searchvalue = '';
					}
				})
			},
			activeCheng(e){
				if(e == 1){
					
				}else if(e==2){
					this.$router.push('/service')
				}else if(e==3){
					this.$router.push('/my')
				}else{
					this.$router.push('/')
				}
			},
			detailInfo(val){/*资讯详情*/
				var s = this;
				this.$router.push('/detail/' + val.infoid+'/'+localStorage.getItem('companyid')+'/'+s.informationid);
			},
		},
		destroyed() {}
	}
</script>
