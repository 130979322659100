<template>
	<div class="h-100 dis-flex-com ft-sz-14">
		<div v-for="(item,index) in JSON.parse(JSON.stringify(serverList))" :key="index" v-if="item.status" >
				<div class="pd-t-10 pd-x-20">
					<div class="pd-y-15 bd-bott dis-flex-row al-c ft-sz-20 ft-w-b ">
						<span class="qiusofont mg-r-10 ft-sz-26 ">{{encodeIcon(item)}}</span>{{item.name}}
					</div>
					<div class="dis-flex-row al-c pd-b-15" style="flex-wrap: wrap;">
						<div class="ziLanMu txt-c dis-flex-com ju-c mg-b-10 mg-r-10" style="width:30%;" v-for="(items,indexs) in item.list" :key="indexs" v-if="items.status">
							<div class="pd-x-5 pd-t-5 pd-b-5 flx-1 dis-flex-com">
								<div class="flx-1 dis-flex-com ju-c bd-r-5" style="box-shadow: 0 0 6px 0 #999999;" @click="productJinRu(items,item)">
									<div>
										<div>
											<span class="qiusofont ft-sz-32">{{encodeIcon(items)}}</span>
										</div>
										<div class="pd-t-5">{{items.title}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pd-t-10 bk-co-f5"></div>
			</div>
		<div class="tabbarheight"></div>
		<van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000" @change="activeCheng">
		  <van-tabbar-item>
		    <span>精选</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6b6;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>资讯</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6ae;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>服务</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6b0;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>我的</span>
		    <template #icon="props">
		      <span class="qiusofont ft-sz-22">&#xe6ac;</span>
		    </template>
		  </van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<style scoped>
	.ziLanMu{width: 93px;height: 106px;}
</style>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import {
		Toast
	} from 'vant';
	var he = require('he');
	var {
		adminGeneral,
		changYueAcions 
	} = http

	export default {
		name: '',
		components: {},
		data() {
			return {
				active:2,
				current:2,
				sourceData:[],
				serverList:util.serverProduct,
				//console.log(serverList),
				interactIcon:['yijianxiang1','fenxianghui'],
				curentDate:parseInt(Number(new Date())/1000)
			}
		},
		created(){},
		mounted() {
			// console.log(this.serverList)
			console.log(util)
			this.serverList.forEach((v,k)=>{
				v.status = false;
			})
			this.pcompanyList();
			
			},
		activated() {},
		methods: {
			pcompanyList(){/*用户获取公司已授权产品列表*/

				var _this = this;
				adminGeneral.userProductList.http({
					"condition":{
						"companyid": localStorage.getItem('companyid'),
						"page_index":0,
						"page_size":100
					}
				}).then(data => {
					if (data.getret === 0) {
						data.list.map((item,index)=>{
						  switch(item.producturl){
							case '/activity/party':{
							  /*活动聚*/
							  _this.serverList[0].status=true;
							  _this.serverList[0].productid = item.productid;
							  /*调查*/
							  _this.serverList[0].list[0].status=true;
							  /*评星*/
							  _this.serverList[0].list[1].status=true;
							  /*投票*/
							  _this.serverList[0].list[2].status=true;
							  /*其他*/
							  _this.serverList[0].list[4].status=true;
							  /*活动*/
							  _this.serverList[0].list[3].status=true;
							  break;
							}
							case '/interact/index':{
								/*互动台*/
								_this.serverList[4].status=true;
								_this.serverList[4].productid = item.productid;
								break;
							}
							case '/businesstrip/index':{
							  /*出差宝*/
							  _this.serverList[1].status=true;
							  _this.serverList[1].productid=item.productid;
							  break;
							}
							case '/changyue':{
								/*畅阅*/
								_this.serverList[5].status=true;
								_this.serverList[5].productid = item.productid;
								_this.manuscriptStatistical(item.productid,function(res){
									if (res.getret == 55) {
										_this.serverList[5].list[1].status = false;
									}else{
										_this.serverList[5].list[1].status = true;
									}
								})
								break;
							}
							case '/meeting/index':{
							  /*会议通*/
							  _this.serverList[6].status=true;
							  _this.serverList[6].productid = item.productid;
							  /*会议室预定*/
							  _this.serverList[6].list[0].status=true;
							  let currenttime = util.formatDate('',_this.curentDate)+' '+'08:00'
							  let currentstamp = util.formatimestamp(currenttime)/1000;
							  /*会议活动*/
							  _this.serverList[6].list[1].status=true;
							  break;
							}
							case '/diycompany/duty':{
							  /*定制岛*/
							  _this.serverList[2].list[4].status=true;
							  _this.serverList[2].productid = item.productid;
							  break;
							}            
							default:{
							  /*福利厅*隐藏*/
							  _this.serverList[2].status=false;
							  _this.serverList[2].productid = item.productid;
							  break;
							}
						  }
						})
					}
				})
			},
			informationTypeList(productid,index){/*获取用户信息类型列表 2001304*/
				var s = this;
				adminGeneral.userInformationTypeList.http({
					"condition":{
						"companyid":localStorage.getItem('companyid'),
						"isshowindex": 1,
						"page_index": 0,
						"page_size": 100,
						"productid": productid,
					}
				}).then(data => {
					if (data.getret === 0) {
						
					}
				})
			},
			manuscriptStatistical(productid,res){
				var s = this;
				changYueAcions.manuscriptStatistical.http({
					"condition":{
						"companyid":localStorage.getItem('companyid'),
						"page_index": 0,
						"page_size": 10,
						"productid": productid,
					}
				}).then(data => {
					res(data);
				})
			},
			encodeIcon(val){
				return he.unescape(val.icon)
			},
			productJinRu(item,val){
				localStorage.setItem('productid',val.productid)
				this.$router.push(item.urls+'?companyid='+localStorage.getItem('companyid'))
			},
			activeCheng(e){
				if(e == 1){
					this.$router.push('/information')
				}else if(e==2){
					
				}else if(e==3){
					this.$router.push('/my')
				}else{
					this.$router.push('/')
				}
			}
		},
		destroyed() {}
	}
</script>
